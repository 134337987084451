export const paincaveLocation = {

    lat: 45.502106,
    lng: 9.222841
}

export const appVersion = {

	iOS: '1.1.5',
	Android: '1.1.6'

}

export const menu = {

    isOpen: false,
    toShow: false
}

export const modalActive = false;

export var abbonamento: any = {

	tipologia: '',
	ore_mensili: '',
	durata_mesi: 0,
	night_mode: 0,
	totale: 0,
	totale_scontato: 0,
	tipologia_sconto: ''

}

// pk_test_eiiKY6TEAUC4DKg8iTYZi4nx00AA5GOcp4
// pk_live_1waZB1g7cwq5SyRT7EAjXcWU00raZGmpRd
export const stripe_key = 'pk_live_1waZB1g7cwq5SyRT7EAjXcWU00raZGmpRd';
