import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonApp, IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Device } from '@ionic-native/device/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { Stripe } from '@ionic-native/stripe/ngx';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CalendarModule } from "ion2-calendar";
import { HTTP } from '@ionic-native/http/ngx';
import { DatePipe } from '@angular/common';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxMaterialTimepickerComponent, NgxMaterialTimepicker24HoursFaceComponent, NgxMaterialTimepickerDialControlComponent  } from 'ngx-material-timepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
  	BrowserModule,
  	IonicModule.forRoot(),
  	HttpClientModule,
    NgxMaterialTimepickerModule,
    BrowserAnimationsModule,
    IonicStorageModule.forRoot({
      name: '__paincave',
      driverOrder: [
        'indexeddb', 'sqlite', 'websql'
      ],
    }),
  	AppRoutingModule,
    CalendarModule],
  providers: [
    OneSignal,
    Device,
    Camera,
    InAppBrowser,
    Stripe,
    StatusBar,
    SplashScreen,
    DatePicker,
    DatePipe,
    HttpClient,
    HTTP,
    Geolocation,
    BarcodeScanner,
    NgxMaterialTimepickerComponent,
    NgxMaterialTimepicker24HoursFaceComponent,
    NgxMaterialTimepickerDialControlComponent,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
