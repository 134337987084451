import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // { path: '', redirectTo: 'starter', pathMatch: 'full' },
  { path: '',
    loadChildren: () => import('./pages/starter/starter.module').then( m => m.StarterPageModule)
  }, 
  { path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'starter',
    loadChildren: () => import('./pages/starter/starter.module').then( m => m.StarterPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'prenotazione1',
    loadChildren: () => import('./pages/prenotazione1/prenotazione1.module').then( m => m.Prenotazione1PageModule)
  },
  {
    path: 'riepilogo-prenotazione',
    loadChildren: () => import('./pages/riepilogo-prenotazione/riepilogo-prenotazione.module').then( m => m.RiepilogoPrenotazionePageModule)
  },
  {
    path: 'profilo',
    loadChildren: () => import('./pages/profilo/profilo.module').then( m => m.ProfiloPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'lista-carte',
    loadChildren: () => import('./pages/lista-carte/lista-carte.module').then( m => m.ListaCartePageModule)
  },
  {
    path: 'doors',
    loadChildren: () => import('./pages/doors/doors.module').then( m => m.DoorsPageModule)
  },
  {
    path: 'password-dimenticata',
    loadChildren: () => import('./pages/password-dimenticata/password-dimenticata.module').then( m => m.PasswordDimenticataPageModule)
  },
  {
    path: 'le-tue-prenotazioni',
    loadChildren: () => import('./pages/le-tue-prenotazioni/le-tue-prenotazioni.module').then( m => m.LeTuePrenotazioniPageModule)
  },
  {
    path: 'presentazione',
    loadChildren: () => import('./pages/presentazione/presentazione.module').then( m => m.PresentazionePageModule)
  },
  {
    path: 'abbonamenti',
    loadChildren: () => import('./pages/abbonamenti/abbonamenti.module').then( m => m.AbbonamentiPageModule)
  },
  {
    path: 'bikelab',
    loadChildren: () => import('./pages/bikelab/bikelab.module').then( m => m.BikelabPageModule)
  },
  {
    path: 'dettaglio-prenotazione/:id',
    loadChildren: () => import('./pages/dettaglio-prenotazione/dettaglio-prenotazione.module').then( m => m.DettaglioPrenotazionePageModule)
  },
  {
    path: 'modal-linee-guida-covid',
    loadChildren: () => import('./pages/modal-linee-guida-covid/modal-linee-guida-covid.module').then( m => m.ModalLineeGuidaCovidPageModule)
  },
  {
    path: 'modal-autocertificazione-covid',
    loadChildren: () => import('./pages/modal-autocertificazione-covid/modal-autocertificazione-covid.module').then( m => m.ModalAutocertificazioneCovidPageModule)
  },
  {
    path: 'promemoria-sanitario',
    loadChildren: () => import('./pages/promemoria-sanitario/promemoria-sanitario.module').then( m => m.PromemoriaSanitarioPageModule)
  },
  {
    path: 'promozioni',
    loadChildren: () => import('./pages/promozioni/promozioni.module').then( m => m.PromozioniPageModule)
  },
];

@NgModule({
  imports: [

    RouterModule.forRoot(routes, {onSameUrlNavigation:'reload', preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
