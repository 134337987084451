import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RestapiService {

	mainEndpoint = "https://services.paincave.it/";

	constructor(private http: HttpClient) { }

	public async get(endpoint: string, options: Object) {
		let promise = new Promise((resolve, reject) => {
		  this.http.get(this.mainEndpoint+endpoint, options)
		    .subscribe((response) => {
		      resolve(response);
		    }, (error) => {
		      reject(error);
		    });
		});

		return promise;
	}

	public async post(endpoint: string, payload: any, options: Object) {

		let promise = new Promise((resolve, reject) => {
		  this.http.post(this.mainEndpoint+endpoint, payload, options).subscribe((response) => {
		    resolve(response);
		  }, (error) => {
		    reject(error);
		  });
		});

		return promise;
	}

	public async delete(endpoint: string, options: Object) {
		let promise = new Promise((resolve, reject) => {
		  this.http.delete(this.mainEndpoint+endpoint, options).subscribe((response) => {
		    resolve(response);
		  }, (error) => {
		    reject(error);
		  });
		});

		return promise;
	}
}
