import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

	constructor(
    private storage: Storage
  ) {

  }

  public async get(key: string) {
    let that = this;
    return new Promise<Array<Object>>(function (resolve, reject) {
      let entities = that.storage.get(key);
      if (entities === null) {
        reject(Error("Nessuna entità presente"));
      } else {
        resolve(entities);
      }
    });
  }

  public async set(key: string, value: object, keyValue: string, strict: boolean = true) {
    let that = this;
    return new Promise<Boolean>(function (resolve, reject) {
      that.get(key).then((entities) => {
        if (entities === null) {
          that.storage.set(key, [value]);
          resolve(true);
        } else {

          let entity = entities.filter(entity => {
            return entity[keyValue] === value[keyValue];
          });

          if (entity.length === 0 || !strict) {
            entities.push(value);
            that.storage.set(key, entities);
            resolve(true);
          } else if (entity.length === 1 || !strict) {
            that.storage.set(key, entities);
            resolve(true);
          } else {
            reject(Error("Database non disponibile"));
          }
        }
      });
    });
  }

  public async unset(key: string) {
    this.storage.remove(key);
  }
}
