import { Component } from '@angular/core';
import { Platform,ActionSheetController } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { RestapiService } from './services/restapi.service';
import { ModalService } from './services/modal.service';
import { UtilsService } from './services/utils.service';
import { StorageService } from './services/storage.service';
import { menu, modalActive } from './environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  menu = menu;
  modalActive = modalActive;

  utente: any = {

    userid: 0,
    token: '',
    nome: '',
    cognome: '',
    foto: 0

  };

  constructor(
    private platform: Platform,
    public actionSheetController: ActionSheetController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public global: UtilsService,
    private restapiService: RestapiService,
    private modalService: ModalService,
    private storageService: StorageService,
    private router: Router,
    private http: HTTP

  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.overlaysWebView(false);
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.http.setServerTrustMode('pinned');
    });
  }

  menuTo(destination) {

    this.router.navigate(['/'+destination])
    this.menu.isOpen = !this.menu.isOpen;

  }

  toggleMenu() {

    this.menu.isOpen = !this.menu.isOpen;

  }

  chiudiMenu() {
    this.menu.isOpen = false;
  }

  logout() {

    this.storageService.unset('utente').then(res => {

      this.storageService.unset('presentazione').then(res => {

        this.menu.isOpen = false;
        this.router.navigate(['/login'], { replaceUrl: true })

      });
    });

  }

  async sceltaPrenotazione() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Tipologia prenotazione',
      buttons: [{
        //text: 'Postazione Virtual Cycling', 
        text: 'Indoor Cycling',
        handler: () => {
          this.checkPrenotabile();
        }
      }, {
        text: 'Annulla',
        role: 'cancel',
        handler: () => {this.modalService.dismissLoading();}
      }]
    });
    await actionSheet.present();
  }

  checkPrenotabile(){

    let that = this;
    this.modalService.showLoading('Attendere, prego...', 100000).then(() => {

      that.storageService.get('utente').then(res => {

        that.utente = res[0];

        that.restapiService.post("check_prenotabile", {

            userid: that.utente.userid,
            token: that.utente.token

          }, {
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'json'
        }).then((response: any) => {

          that.modalService.dismissLoading();
          if(response.status == "OK"){

            that.router.navigate(['/prenotazione1'])
            this.menu.isOpen = false;

          } else if ( response.data.status !== 'OK' && response.data.status !== 'KO'){

            this.modalService.showAlert(
              "Attenzione!",
              response.data.message,
              [
                {
                  text: 'Confermo',
                  handler: function () {
  
                    var url_cm = new String ('https://app.paincave.it/checkcm/checkcm.php?id_utente=');
                    var url_total = url_cm.concat(response.data.status.toString());
                    fetch(url_total, { method: "GET" });                  
                   
                  }
                   
                }
                
              ]
  
            );
            
          } else {

            that.modalService.showAlert(
              "Attenzione!",
              response.data.message,
              [
                {
                  text: 'Ok'
                }
              ]
            );

          }
        });

      });

    });

  }

}
