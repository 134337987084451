import { Injectable } from '@angular/core';
import { AlertController, ToastController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private alert: any;
  private toast: any;
  private loading: any;

  constructor(
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
  ) { }

  async showAlert(header: string, message: string, buttons: Array<any>, ) {
    this.alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: buttons,
      animated: true,
    });

    await this.alert.present();
  }

  async showToast(message: string, duration = 2000, position = 'middle', color = 'dark') {
    this.toast = await this.toastCtrl.create({
      message: message,
      duration: duration,
      position: position as 'top' | 'bottom' | 'middle',
      color: color,
      animated: true,
      showCloseButton: true,
      closeButtonText: 'x'
    });

    await this.toast.present();
  }

  async showLoading(message: string, duration = 2000) {
    this.loading = await this.loadingCtrl.create({
      message: message,
      duration: duration
    });

    await this.loading.present();
  }

  async dismissAlert() {
    await this.alert.dismiss();
  }

  async dismissToast() {
    await this.toast.dismiss();
  }

  async dismissLoading() {
    await this.loading.dismiss();
  }

}

