import { Injectable } from '@angular/core';
import { Platform,ModalController,NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

	public showLoader: boolean;

	constructor(private platform: Platform) {

		this.showLoader = false;

	}

	async openURL(url,target = ''){

		if(this.platform.is('cordova') == true){

			window.open(url, '_system');

		} else {

			if(target != ''){

				window.open(url, target);

			} else {

				window.open(url, '_blank');

			}

		}

	}
}
